.AppOLD {
  text-align: center;
}

body {overflow-x: hidden;}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevents scrollbars in the main container */
}

.App-logo {  
  max-height: 75px;  
  max-width: 65vw; 

  padding: 10px;
  
  pointer-events: none;
  align-items: center;
  justify-content: center;
}
.loginForm {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

}

.AppName {  
  height: 100%;    
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: rgb(3, 180, 171);    
  pointer-events: none;
  align-items: center;
  justify-content: center;  
}

.App-logo2 {  
  height: 40px;    
  width: 40px;
  pointer-events: none;
  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logoOLD {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-headerOLD {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 3vmin);
  color: white;
}

.HeaderDivider {
  background-color: #ffffff;
  height: 40px;
  width: 100%;  
  display: flex;
}

.LeftHeader {
  width: 35%;
  height: 100%;  
  align-items: left;
  justify-content: left;
  padding-top: 5px;
}

.RightHeader {
  width: 5%;
  height: 100%;
  align-items: right;
  justify-content: right;    
}
.MiddelHeader {
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;    
}

.ResponseTextArea {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  /* Adjust the height to accommodate space for the bottom-bar */
  height: calc(90vh - 110px ); /* Adjust the 50px to the height of your bottom-bar */
  border: 0;
  overflow-y: auto; /* Changed to auto to handle overflow gracefully */
  resize: none;
  box-sizing: border-box;
}

/* Make adjustments for smaller screens */
@media (max-width: 600px) { /* Example breakpoint for smaller screens */
  .ResponseTextArea {
    height: calc(90vh - 105px - 60px); /* Adjust height if needed for smaller screens */
  }
}

.ResponseTextArea:focus {
  outline: none !important;
  border:0px solid red;  
}

.textarea-container {
  position: relative;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.QueryTextArea {
  width: 100%;
  /* height: 7vh; */
  min-height: 30px;
  padding: 1vh;
  padding-right: 7vh;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none; /* Remove default outline */
  resize: none; /* Disable resizing */
  overflow: hidden;
  transition: border-color 0.3s ease;
}

.QueryTextArea:focus {
  /* outline: none !important;
  border:0px solid red;   */
  border-color: #007bff;
}

.submit-button {
  position: absolute;
  top: 50%; 
  right: 4vh; 
  transform: translateY(-50%);
  background-color: #007bff;
  color: white;
  padding: 4px 4px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button.disabled {
  cursor: not-allowed;
  opacity: 0.6; 
  pointer-events: none;
}

.ResponseContainer {
  color: black;
  display: flex;
  flex-direction: column;
}

.BeforeResponse {
  color: black;
  display: flex;
  flex-direction: column;
}

.NLQResponse {
  color: black;
  display: flex;
  flex-direction: column;
}

.NLCResponse {
  color: black;
  display: flex;
  flex-direction: column;
}

.NLHResponse {
  color: black;
  display: flex;
  flex-direction: column;
}

.user {
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #2054cd; /* Change color as needed */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.username-letter {
  font-weight: lighter;
  font-size: 16px; /* Adjust size as needed */
  color: #fff; /* Change color as needed */
}

.Question {
  /* font-weight: bold; */
  /* margin-left: auto; */
  margin-left: 20px;
  word-wrap: break-word;
}

.Answer {  
  margin-left: 20px;
  margin-right: auto;
  
}

.AnswerContainer {
  display: flex;
  flex-direction: row;
  width: fit-content ;
  margin-left: auto;
  margin-right: auto;
 
 

}

.muiColumnHeader {
  font-weight:bolder;
  color: black;
  background-color: lightskyblue;
}

.Chart {
  height: 100px;
  width: 200px;
  transform: scale(100%,100%);
  align-self: center;
}

.App-link {
  color: #61dafb;
}

.executeMethod {
  margin-left: 20px;
}

.lookupMethod {
  margin-left: 40px;
}

.source_document {
  font-size: x-small;
  margin-left: 20px;
}



.spinner {
  color: aquamarine;  
}

.spinnerContainer {
  padding-top: 10px;
  margin-left: 20px;  
}

.Photo {
  width: 200px;
  margin-left: 20px;
}


.overlay {

  position: absolute;
  top:30%;
  left: 30%;
  text-align: center;
  z-index:1;
}
.msg{
  margin-left: 20px;
  margin-right: auto;
  color: red;
}


.feedbackButton.MuiButton-root{
  background-color: #007bff;
  box-shadow: none;
  margin-left: 20px;
}

.feedbackButton.chosen{
  pointer-events: none;
  opacity: 0.6;
}

.feedbackButton:disabled{
  color: gray; 
  background-color: #f5f5f5; 
  opacity: 0.6;
  pointer-events: none;
  box-shadow: none;
}

.feedbackButton.MuiButton-root:hover {
  box-shadow: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
