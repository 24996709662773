.bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1000; /* Ensure it appears above other content */
    height: 130px;
}

.text-field {
    flex: 1;
    margin-right: 8px;
}

.input-container {
    display: flex;
    align-items: center;
    width: 60%; 
    /* max-width: 800px;  */
}


.submit-button {
    height: 56px; /* Match the height of the TextField */
    min-width: 56px;
}