.pwdaccept{
    color: green;
    font-size: 70%;
    
}
.pwdaccept::before{
content: '✓ '

}
.pwdnoaccept{
    color: red;
    font-size: 70%;
}
.pwdList{
    list-style: none;
}
.pwdnoaccept::before{
    content: '✘';
}

.inputText {
    width: 670px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  /* .footer img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    transform: rotate(180deg);
    box-sizing: border-box;
  } */